import React from "react"
import { Router } from "@reach/router"

import Wishlist from "components/wishlistComponent/Wishlist"

const WishlistPage = props => {
  return (
    <Router basepath="/wishlist">
      <Wishlist path="/:wishlistId" />
      <Wishlist path="/" />
    </Router>
  )
}

export default WishlistPage
