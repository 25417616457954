import React from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import { Typography, IconButton, CircularProgress } from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"
import formatCurrencyString from "utils/formatCurrencyString"
import useShopify from "shopify"
import { useWishlist } from "wishlist"

const LineItem = props => {
  const { itemId, product, canDeleteItems, ...rest } = props

  const {
    title,
    images,

    wpProduct: {
      uri,
      acfProduct: { shortDescription },
    },
  } = product || {}
  const featuredImage = images?.[0]

  const { checkout } = useShopify()
  const { removeItemFromWishlist, loading } = useWishlist()

  return (
    <Container {...rest}>
      {featuredImage?.originalSrc && (
        <ImageContainer>
          <img src={featuredImage.originalSrc} alt={featuredImage?.altText} />
        </ImageContainer>
      )}

      <Info>
        <Link
          to={uri}
          css={css`
            color: inherit;
            display: inline-block;
          `}
        >
          <Title variant="h5">{title}</Title>
        </Link>

        {shortDescription && (
          <SubTitle variant="subtitle2">{shortDescription}</SubTitle>
        )}
      </Info>

      <Price>
        <Typography>
          {checkout?.currencyCode}{" "}
          {product?.priceRangeV2?.minVariantPrice?.amount &&
            formatCurrencyString({
              amount: product?.priceRangeV2?.minVariantPrice?.amount,
              currencyCode: checkout?.currencyCode,
            })}
        </Typography>
      </Price>

      {canDeleteItems ? (
        <DeleteButton
          loading={loading}
          onClick={() => {
            removeItemFromWishlist(itemId)
          }}
        />
      ) : (
        <div
          css={css`
            width: 48px;
          `}
        ></div>
      )}
    </Container>
  )
}

export default LineItem

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 10px 16px;

  @media (min-width: 768px) {
    flex-wrap: nowrap;
    align-items: center;
  }
`

const ImageContainer = styled.div`
  flex-shrink: 0;
  width: 90px;
  margin-right: 16px;
  margin-bottom: 10px;

  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`

const Title = styled(Typography)`
  && {
    @media (max-width: 767px) {
      margin-bottom: 10px;
    }

    @media (max-width: 960px) {
      font-size: 20px;
    }
  }
`

const SubTitle = styled(Typography)`
  && {
    @media (max-width: 767px) {
      margin-bottom: 10px;
    }
  }
`

const Info = styled.div`
  width: 100%;
  margin-right: 16px;

  @media (min-width: 768px) {
    padding-right: 32px;
  }
`

const Price = styled.div`
  flex-shrink: 0;
  width: 120px;
  margin-right: 16px;
`

const DeleteButton = ({ loading, ...rest }) => {
  return (
    <IconButton {...rest}>
      <DeleteIcon
        css={css`
          transition: opacity 0.3s ease-in-out;
          ${loading
            ? css`
                opacity: 0;
              `
            : css`
                opacity: 1;
              `}
        `}
      />

      <CircularProgress
        size={20}
        css={css`
          position: absolute;
          top: 14px;
          left: 14px;
          transition: opacity 0.2s ease-in-out;
          ${loading
            ? css`
                opacity: 1;
              `
            : css`
                opacity: 0;
              `}
        `}
      />
    </IconButton>
  )
}
