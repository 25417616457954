import React, { useRef } from "react"
import styled, { css } from "styled-components"
import { useLazyQuery } from "@apollo/client"

import Helmet from "react-helmet"
import { useStaticQuery, graphql, Link } from "gatsby"

import { Typography, Button, IconButton, Tooltip } from "@material-ui/core"

import FacebookIcon from "@material-ui/icons/Facebook"
import TwitterIcon from "@material-ui/icons/Twitter"
import PinterestIcon from "@material-ui/icons/Pinterest"
import FileCopyIcon from "@material-ui/icons/FileCopy"
import EmailIcon from "@material-ui/icons/Email"

import { Edges } from "components"
import WishlistItem from "./WishlistItem"
import { WISHLIST_QUERY } from "apollo/query/WISHLIST_QUERY"
import { useWishlist } from "wishlist"

const Wishlist = props => {
  const { location, wishlistId: urlWishlistId, ...rest } = props

  const { wishlist } = useWishlist()

  const allProducts = useAllProducts()

  const wishlistId = urlWishlistId

  const [getWishlist, { data, loading, called }] = useLazyQuery(
    WISHLIST_QUERY,
    {
      variables: {
        id: wishlistId,
      },
    }
  )

  !data && !loading && !called && urlWishlistId && getWishlist()

  const itemIds = urlWishlistId
    ? data?.wishlist?.acf?.items &&
      data.wishlist.acf.items.map(({ itemId }) => itemId)
    : wishlist?.items && wishlist.items.map(itemId => itemId)

  const wishlistUrl = `${location?.origin}/wishlist/${wishlist?.id}`
  const encodedWishlistUrl = encodeURIComponent(wishlistUrl)

  const urls = {
    facebook: `https://www.facebook.com/sharer.php?u=${encodedWishlistUrl}`,
    twitter: `https://twitter.com/share?url=${encodedWishlistUrl}&text=`,
    pinterest: `http://pinterest.com/pin/create/button/?url=${encodedWishlistUrl}&description=&media=`,
    email: `mailto:?subject=My%20Wishlist%20on%20Linda%20Meyer-Hentschel&body=${encodedWishlistUrl}&title=My+wishlist+on+Linda+Meyer-Hentschel`,
    copy: wishlistUrl,
  }

  const wishlistUrlRef = useRef(null)

  const handleCopyUrl = () => {
    if (typeof window !== `undefined` && wishlistUrlRef?.current) {
      wishlistUrlRef.current.select()
      wishlistUrlRef.current.setSelectionRange(0, 99999)
      document.execCommand("copy")
    }
  }

  return (
    <Container {...rest}>
      <Helmet>
        <title>Wishlist</title>
      </Helmet>

      <Edges size="lg">
        <Typography variant="h3" gutterBottom>
          {!urlWishlistId && "Your"} Wishlist{" "}
        </Typography>

        {(!itemIds || itemIds?.length === 0) && (
          <div>
            <Typography
              gutterBottom
              css={css`
                margin-bottom: 30px;
              `}
            >
              Your wishlist is currently empty!
            </Typography>
            <Button
              to="/collections/boutique"
              component={Link}
              variant="contained"
            >
              Browse Boutique
            </Button>
          </div>
        )}

        {itemIds && itemIds?.length !== 0 && (
          <div
            css={css`
              border: 1px solid #dddddf;
            `}
          >
            <ItemsHeader>
              <Typography
                css={css`
                  width: 90px;
                  margin-right: 16px;
                  flex-shrink: 0;
                `}
              >
                Image
              </Typography>
              <Typography
                css={css`
                  width: 100%;
                  margin-right: 16px;
                `}
              >
                Product
              </Typography>
              <Typography
                css={css`
                  flex-shrink: 0;
                  width: 120px;
                  margin-right: calc(
                    16px + 48px
                  ); // 48px is width of delete button
                `}
              >
                Price
              </Typography>
            </ItemsHeader>

            <Items>
              {itemIds.map(itemId => {
                const product =
                  allProducts &&
                  allProducts.find(item => item.storefrontId === itemId)

                return (
                  <WishlistItem
                    canDeleteItems={!urlWishlistId}
                    key={itemId}
                    itemId={itemId}
                    product={product}
                  />
                )
              })}
            </Items>
          </div>
        )}

        {!urlWishlistId && itemIds && itemIds.length > 0 && (
          <div
            css={css`
              margin: 30px 0;
            `}
          >
            <Typography variant="h5">Share your wishlist</Typography>

            <Tooltip title="Share on Facebook">
              <IconButton component="a" target="_blank" href={urls.facebook}>
                <FacebookIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Share on Twitter">
              <IconButton component="a" target="_blank" href={urls.twitter}>
                <TwitterIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Share on Pinterest">
              <IconButton component="a" target="_blank" href={urls.pinterest}>
                <PinterestIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Share in Email">
              <IconButton component="a" target="_blank" href={urls.email}>
                <EmailIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Copy sharable link">
              <IconButton onClick={handleCopyUrl}>
                <FileCopyIcon />
              </IconButton>
            </Tooltip>
            <input
              ref={wishlistUrlRef}
              value={wishlistUrl}
              css={css`
                pointer-events: none;
                opacity: 0;
              `}
            />
          </div>
        )}
      </Edges>
    </Container>
  )
}

export default Wishlist

const Container = styled.div`
  padding: 60px 0;
  background: white;
  height: 100%;
  min-height: 68vh;
`

const Items = styled.div`
  padding: 16px 0;
`
const ItemsHeader = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: flex;
    padding: 16px;
    border-bottom: 1px solid #dddddf;
  }
`

const useAllProducts = () => {
  const {
    allShopifyProduct: { nodes },
  } = useStaticQuery(graphql`
    {
      allShopifyProduct {
        nodes {
          ...ProductFragment
          wpProduct {
            uri
            acfProduct {
              shortDescription
            }
          }
        }
      }
    }
  `)

  return nodes
}
